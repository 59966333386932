import React from "react";
import "./Languages.css";

const Languages = () => {
  const data = [
    {
      title: "Frontend Tech",
      contents: ["React JS", "Redux", "TypeScript", "HTML/CSS", "JavaScript"],
    },
    {
      title: "Backend Tech",
      contents: ["Node JS", "Express JS", "Mongo DB"],
    },
    {
      title: "Design",
      contents: ["Bootstrap", "Figma", "Canva", "Material UI", "Tailwind CSS"],
    },
    {
      title: "Programming",
      contents: ["C/C++", "Java", "Python", "DSA", "OOPS"],
    },
    {
      title: "Software",
      contents: ["VS Code", "Intellij", "Pycharm", "Postman", "Photoshop"],
    },
  ];

  return (
    <section id="languages" data-aos="fade">
      {data.map((item) => (
        <div className="language-box" key={item.title}>
          <h3>{item.title}</h3>
          <ul>
            {item.contents.map((content) => (
              <li key={content}>{content}</li>
            ))}
          </ul>
        </div>
      ))}
    </section>
  );
};

export default Languages;
