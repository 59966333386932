import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <section id="footer">
      <footer>
        <span>Copyright &copy;2022</span>

        <div className="social-links">
          <a href="https://api.whatsapp.com/send?phone=917276698091&text=Hi">
            <i className="fa-brands fa-whatsapp social-ico"></i>
          </a>

          <a href="https://www.linkedin.com/in/prajwaltikhe">
            <i className="fa-brands fa-linkedin social-ico"></i>
          </a>

          <a href="https://www.instagram.com/prajwal.tikhe/">
            <i className="fa-brands fa-instagram social-ico"></i>
          </a>

          <a href="https://www.facebook.com/prajwal.tikhe.92">
            <i className="fa-brands fa-facebook social-ico"></i>
          </a>
        </div>
        <a
          href="https://goo.gl/maps/64Zz8rZnVAGhQ8hT9"
          rel="noreferrer"
          target="_blank"
          className="footer-logo"
        >
          <i className="fa-solid fa-location-dot" /> Nashik, India
        </a>
      </footer>
      <p className="footer-end">
        Made with <i className="fa-solid fa-heart" /> by Prajwal Tikhe
      </p>
    </section>
  );
};

export default Footer;
