import React from "react";
import "./Career.css";
import CV from "../../assets/cv/cv.pdf";

const Career = () => {
  const careers = [
    {
      title: "Web Design Developer Intern",
      name: "Cultivist",
      year: "July 2021 - Jan 2022",
      desc: "Designed a dual ecommerce dynamic website with a payment gateway and social media integration. Deploy and maintain a website on hosted network i.e. Heroku cloud platform. Perform various testing methodologies.",
    },
  ];

  const educations = [
    {
      title: "Bachelor of Engineering in E&TC",
      name: "MVPS KBT College of Engineering Nashik",
      year: "Aug 2018 - July 2022",
      grade: "7.75 CGPA",
    },
    {
      title: "Science (PCMB) - CBSE",
      name: "Kendriya Vidyalaya, ISP, Nashik",
      year: "June 2016 - July 2018",
      grade: "6.70 CGPA",
    },
    {
      title: "General - CBSE",
      name: "Kendriya Vidyalaya, CR, Manmad, Nashik",
      year: "Apr 2006 - May 2016",
      grade: "9.20 CGPA",
    },
  ];

  return (
    <section id="career">
      <div className="career-education">
        <div className="career" data-aos="fade-right">
          <h3>Work Experience</h3>

          <div className="c-b-container">
            {careers.map((career, index) => (
              <div className="c-box" key={index}>
                <h4>{career.title}</h4>
                <strong>{career.name}</strong>
                <p>{career.year}</p>
                <p className="career-desc">{career.desc}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="education" data-aos="fade-left">
          <h3>Education</h3>

          <div className="c-b-container">
            {educations.map((education, index) => (
              <div className="c-box" key={index}>
                <h4>{education.title}</h4>
                <strong>{education.name}</strong>
                <p>{education.year}</p>
                <p className="grade">{education.grade}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="btn-c">
        <a href={CV} download className="btn-link">
          GET CV <i className="fa-solid fa-download"></i>
        </a>
      </div>
    </section>
  );
};

export default Career;
