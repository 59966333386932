import React from "react";
import "./Summary.css";
import CV from "../../assets/cv/cv.pdf";

const Summary = () => {
  return (
    <section id="summary">
      <div className="summary-heading" data-aos="fade-right">
        <strong>My Skillset</strong>

        <h2>Full Stack Web Developer</h2>

        <a href={CV} download className="btn-link">
          Get CV <i className="fa-solid fa-download"></i>
        </a>
      </div>

      <div className="summary-details" data-aos="fade-left">
        Hey, I am an ECE graduate from KBTCOE, SPPU. Currently completed
        graduation and looking for job opportunities.
        <div style={{ marginBottom: "7px" }} />
        I am experienced in MERN Stack for developing a dual <br /> e-commerce
        website (6 month internship at Cultivist).
        <div style={{ marginBottom: "7px" }} />
        I have worked for several projects in past 2 years in
        <div style={{ marginBottom: "4px" }} />
        Frontend tech (ReactJs, Redux, TypeScript, HTML/CSS, JS),
        <br />
        Backend tech (Node Js, Express Js, Mongo dB) and <br />
        Design (Bootstrap, Figma, Material UI, Tailwind CSS)
        <div style={{ marginBottom: "7px" }} />
        Skillful in C/C++, Java, Python and Data Structures with hands on
        experience on web development tools.
        <div style={{ marginBottom: "7px" }} />
        The contented feeling of making a difference is what drives me to work
        on projects that have an impact in the real world.
        <div style={{ marginBottom: "7px" }} />
        Happy Learning !
      </div>
    </section>
  );
};

export default Summary;
