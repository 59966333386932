import React from "react";
import "./Contact.css";

const Contact = () => {
  return (
    <section id="contact" data-aos="fade-down">
      <strong>What's Next</strong>
      <h3>Let's work together</h3>
      <h4>Got a Project for me or need help?</h4>
      <p>
        I help people, brands and companies turn big creative ideas into
        beautiful dynamic web applications and experiences.
      </p>
      <a
        href="mailto:prajwaltikhe10@gmail.com"
        className="btn-link contact-btn"
      >
        Write Me An Email <i className="fa-solid fa-envelope"></i>
      </a>
    </section>
  );
};

export default Contact;
