import React from "react";
import "./About.css";

const About = () => {
  return (
    <section id="about">
      <div className="about-container">
        <div className="about-heading" data-aos="flip-left">
          <strong>About me</strong>
          <h3>Designing with passion for Problem Solving</h3>
          <a href="tel:+917276698091" className="btn-link">
            +91 72766 98091 <i className="fa-solid fa-phone-flip"></i>
          </a>
        </div>

        <div className="about-details" data-aos="flip-right">
          Prajwal Tikhe is an E&TC graduate from SPPU, currently exploring new
          opportunities in web and software development.
          <div style={{ marginBottom: "7px" }} />
          He is experienced in MERN Stack for developing a dual e-commerce
          dynamic website for a startup. He would like to learn more skills and
          gain knowledge.
          <div style={{ marginBottom: "7px" }} />
          He has designed several websites using React JS, Node Js, Express Js,
          MongoDB, Redux, HTML, CSS, JavaScript, TypeScript, Bootstrap, Postman,
          Material UI, Tailwind CSS and Heroku Cloud & Google Firebase Hosting.
          <div style={{ marginBottom: "7px" }} />
          If you think that he might be a good fit for current or future roles
          in your company, do contact below. He will be happy to send a copy of
          his resume or any other information.
          <div style={{ marginBottom: "7px" }} />
          Even if you aren't aware of any open roles he might be a fit for, He'd
          love to connect and stay in touch.
          <div style={{ marginBottom: "7px" }} />
          Best Regards, <br /> Prajwal Tikhe.
        </div>
      </div>
    </section>
  );
};

export default About;
