import React from "react";
import "./Home.css";
import Avatar from "../../assets/images/avatar.jpg";

const Home = () => {
  return (
    <section id="home">
      <div className="home-text" data-aos="fade-down">
        <strong>Hello, it's me</strong>
        <h1>Prajwal Tikhe</h1>
        <p>
          Hi, My name is Prajwal Tikhe and I'm a Full Stack Web Developer, UI
          designer. Welcome to my personal Website.
        </p>
        <a
          href="https://api.whatsapp.com/send?phone=917276698091&text=Hi"
          className="hire-me"
        >
          Hire me
        </a>{" "}
        <br /> <br />
        <a href="#summary" className="btn-link">
          Scroll For Me
        </a>
      </div>

      <div className="home-img" data-aos="fade-up">
        <div className="img-box">
          <img src={Avatar} alt="Avatar" />

          <h2>
            Prajwal Tikhe <br />
            <span>Full Stack Web Developer</span>
          </h2>

          <div className="social">
            <a href="https://api.whatsapp.com/send?phone=917276698091&text=Hi">
              <i className="fa-brands fa-whatsapp"></i>
            </a>

            <a href="https://www.linkedin.com/in/prajwaltikhe">
              <i className="fa-brands fa-linkedin"></i>
            </a>

            <a href="https://www.instagram.com/prajwal.tikhe/">
              <i className="fa-brands fa-instagram"></i>
            </a>

            <a href="https://www.facebook.com/prajwal.tikhe.92">
              <i className="fa-brands fa-facebook"></i>
            </a>
          </div>

          <a href="#!" className="hire-me">
            Hire me
          </a>
        </div>
      </div>
    </section>
  );
};

export default Home;
