import React from "react";
import "./Project.css";

import Project1 from "../../assets/images/Project1.png";
import Project2 from "../../assets/images/Project2.png";
import Project3 from "../../assets/images/Project3.png";
import Project4 from "../../assets/images/Project4.png";
import Project5 from "../../assets/images/Project5.png";
import Project6 from "../../assets/images/Project6.png";
import Project7 from "../../assets/images/Project7.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Navigation, Pagination } from "swiper";

const Project = () => {
  const data = [
    {
      src: Project1,
      url: "https://www.cultivist.co.in/",
    },
    {
      src: Project2,
      url: "https://spacex-react-web.herokuapp.com/",
    },
    {
      src: Project3,
      url: "https://prajwaltikhe1.github.io/iDragon-Adventures-Game/",
    },
    {
      src: Project4,
      url: "https://prajwaltikhe1.github.io/iEducate-Transforming-Online-Education/",
    },
    {
      src: Project5,
      url: "https://prajwaltikhe1.github.io/Racing-Car-Animation/",
    },
    {
      src: Project6,
      url: "https://prajwaltikhe1.github.io/Creative-Agency/",
    },
    {
      src: Project7,
      url: "https://prajwaltikhe1.github.io/iBlog-Heaven-for-bloggers/",
    },
  ];

  return (
    <section id="projects">
      <div className="project-headings">
        <h3>Recent Projects</h3>
      </div>

      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 50,
          },
        }}
        modules={[Navigation, Pagination]}
        className="mySwiper"
      >
        {data.map((project) => (
          <SwiperSlide key={project.src}>
            <div className="project-box">
              <a href={project.url} rel="noreferrer" target="_blank">
                <img src={project.src} alt="project" />
                <div className="p-overlayer">
                  <strong>Outserved Reports</strong>
                </div>
              </a>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="github-btn">
        <a href="https://github.com/PrajwalTikhe1" className="btn-link">
          <span className="github-text">My Github</span>{" "}
          <i className="fa-brands fa-github"></i>
        </a>
      </div>
    </section>
  );
};

export default Project;
